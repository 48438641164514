<template>
    <div class="container">
        <div class="text-center mt-4 mb-4">
            <h4>Потоки для дисциплины {{selectableFlows_form?.discipline?.educationDiscipline?.name}}
                - {{selectableFlows_form?.discipline?.educationDiscipline?.language?.native_name}}
                ({{selectableFlows_form?.discipline?.credit}} кредитов)
                Семестр {{selectableFlows_form?.discipline?.semester}}
            </h4>
        </div>


        <Dialog header="Добавление потока" v-model:visible="displayAddFlowsModal" :style="{width: '50vw'}">
            <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Название потока</label>
                <div class="col-md-9 p-col-12">
                    <InputText type="text" @change="changeNewSelectableFlowsData('name', $event)"/>
                </div>
            </div>
            <template #footer>
                <Button label="Отмена" icon="pi pi-times" @click="closeAddFlowsModal" class="p-button-text"/>
                <Button label="Добавить" icon="pi pi-check" @click="postSelectableFlows" autofocus/>
            </template>
        </Dialog>

        <Button label="Добавить" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="openAddFlowsModal"/>


        <div class="mt-3 mb-3">
            <DataTable :value="selectableFlows_form.selectableFlowsInfos" :paginator="true" :rows="10"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       :rowsPerPageOptions="[10,20,50]"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                       stripedRows responsiveLayout="scroll">
                <Column field="name" header="Название"></Column>
                <Column header="Группы">
                    <template #body="{data}">
                        <router-link :to="'/selectable-groups?selectable_flow_id=' + data.id">
                            Перейти
                        </router-link>
                    </template>
                </Column>

                <Column style="min-width:8rem">
                    <template #body="{data}">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2"
                                @click="openEditFlowsModal(data.id)"/>
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mx-1"
                                @click="deleteFlowsConfirm(data.id)"/>
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog header="Редактировать" v-model:visible="displayEditFlowsModal" :modal="true" :style="{width: '50vw'}">
            <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Название потока</label>
                <div class="col-md-9">
                    <InputText type="text" :value="selectableFlows_form.editSelectableFlow?.name"
                               @change="changeSelectableFlowsData('name', $event)"/>
                </div>
            </div>
            <template #footer>
                <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeEditFlowsModal"/>
                <Button label="Обновить" icon="pi pi-check" autofocus @click="putSelectableFlows"/>
            </template>
        </Dialog>

        <ConfirmDialog></ConfirmDialog>

    </div>
</template>

<script>

    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "SelectableFlows",
        data() {
            return {
                formation_education_program_id: +this.$route.query.formation_education_program_id || 1,
                education_curriculum_id: +this.$route.query.education_curriculum_id || 1,
                displayAddFlowsModal: false,
                displayEditFlowsModal: false,
            }
        },
        computed: {
            ...mapState('selectableFlows', ['selectableFlows_form'])
        },

        methods: {
            ...mapActions('selectableFlows', ['POST_SELECTABLE_FLOWS_DATA', 'GET_SELECTABLE_FLOWS_DATA', 'DELETE_SELECTABLE_FLOWS_DATA',
                'PUT_SELECTABLE_FLOWS_DATA', 'GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM']),
            ...mapMutations('selectableFlows', ['SET_NEW_SELECTABLE_FLOWS_DATA', 'SET_FORMATION_EDUCATION_PROGRAM_ID', 'SET_EDUCATION_CURRICULUM_ID',
                'CHANGE_SELECTABLE_FLOW_DATA', 'SET_EDIT_SELECTABLE_FLOW']),

            deleteFlowsConfirm(deleteSelectableFlowsId) {
                this.$confirm.require({
                    message: 'Вы точно хотите удалить этот поток?',
                    header: 'Потверждение удаления',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        await this.DELETE_SELECTABLE_FLOWS_DATA(deleteSelectableFlowsId)
                        await this.GET_SELECTABLE_FLOWS_DATA()
                        this.$message({text: 'Поток удален'})
                    }
                });
            },
            openAddFlowsModal() {
                this.displayAddFlowsModal = true
            },
            openEditFlowsModal(editId) {
                const editSelectableFlow = this.selectableFlows_form.selectableFlowsInfos.find(i => i.id == editId)
                this.SET_EDIT_SELECTABLE_FLOW({...editSelectableFlow})
                this.displayEditFlowsModal = true
            },
            closeAddFlowsModal() {
                this.displayAddFlowsModal = false
            },
            closeEditFlowsModal() {
                this.displayEditFlowsModal = false
            },
            changeNewSelectableFlowsData(property, e, val = 'value') {
                let value = e.target[val]
                console.log(value, "value")
                this.SET_NEW_SELECTABLE_FLOWS_DATA({property, value})
            },
            changeSelectableFlowsData(property, e, val = 'value') {
                let value = e.target[val]
                this.CHANGE_SELECTABLE_FLOW_DATA({property, value})
            },
            async postSelectableFlows() {
                this.displayAddFlowsModal = false
                const res = await this.POST_SELECTABLE_FLOWS_DATA()
                if (res) {
                    await this.GET_SELECTABLE_FLOWS_DATA()
                    this.$message({text: 'Поток успешно создан'})
                    return
                }
                this.$error({text: 'При создании возникла ошибка'})
            },
            async putSelectableFlows() {
                this.displayEditFlowsModal = false
                const res = await this.PUT_SELECTABLE_FLOWS_DATA()
                if (res) {
                    await this.GET_SELECTABLE_FLOWS_DATA()
                    this.$message({text: 'Поток обновлен'})
                    return
                }
                this.$error({text: 'При обновлении возникла ошибка'})
            }
        },
        async mounted() {
            await this.SET_FORMATION_EDUCATION_PROGRAM_ID(this.formation_education_program_id)
            await this.SET_EDUCATION_CURRICULUM_ID(this.education_curriculum_id)
            await this.GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM()
            await this.GET_SELECTABLE_FLOWS_DATA()
        }
    }
</script>

<style scoped>

</style>